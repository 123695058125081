import { Component, Vue } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';
import { Token } from '@/support/Token';
import { User } from '@/models/User';
import ErrorHandler from '@/support/ErrorHandler';
import { identifyHostname } from '@/support/Client';
import { UserFilter } from '@/models/UserFilter';
import { Application } from '@/support/Application';

@Component<Login>({
  beforeRouteUpdate(to, from, next) {
    next();

    this.$route.name === 'logout' ? this.logout() : null;
  },
})
export default class Login extends Vue {
  public $pageTitle!: string;

  public oAuthServer: string = identifyHostname() || '';

  public oAuthClientId = 1;

  public oAuthSecret = 'secret';

  public oAuthScope = '*';

  public username = '';

  public password = '';

  public token = '';

  private valid = true;

  protected logoPath = '@/assets/images/logos/';

  protected environment = {};

  protected environments = [
    {
      logo: `${this.logoPath}logo-10be.png`,
      company: '10be',
    },
    {
      logo: `${this.logoPath}logo-atabix.png`,
      company: 'atabix',
    },
    {
      logo: `${this.logoPath}logo-e.png`,
      company: 'ced',
    },
    {
      logo: `${this.logoPath}logo-dog.png`,
      company: 'dog',
    },
    {
      logo: `${this.logoPath}logo-nivre.png`,
      company: 'nivre',
    },
    {
      logo: `${this.logoPath}logo-img.png`,
      company: 'tcmg',
    },
  ];

  public mounted() {
    if (Token.get('refresh')) {
      this.login('refresh');
    }

    this.$pageTitle = this.$t('auth.login').toString();
    this.$route.name === 'logout' ? this.logout() : null;

    const testEnvName = '10be';
    this.environments.forEach((environment) => {
      if (testEnvName === environment.company) {
        this.environment = environment;
      }
    });
  }

  protected get isAcceptance() {
    return Application.config('APP_ENV') === 'acceptatie';
  }

  public login(loginType: string) {
    const payload:AuthenticationPayload = {
      client_id: this.oAuthClientId,
      client_secret: this.oAuthSecret,
      scope: this.oAuthScope,
      grant_type: loginType && loginType === 'refresh' ? 'refresh_token' : 'password',
    };

    if (loginType && loginType === 'refresh') {
      payload.refresh_token = Token.get('refresh');
    } else {
      payload.username = this.username;
      payload.password = this.password;
      payload.token = this.token;
    }

    const headers = {};

    if (window.location.hostname !== 'localhost') {
      const application = window.location.host.split('.')[0];
      (headers as any)['X-Application'] = application;
    }

    const client = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });

    client
      .post('/oauth/token', payload)
      .then((response) => {
        if (response.data.link) {
          window.location.href = `${response.data.link}?token=${response.data.access_token}`;
          return;
        }

        if (response.data.access_token) {
          Token.delete(['access', 'refresh']);
          Token.set({ access: response.data.access_token, refresh: response.data.refresh_token });
          new User()
            .include(['organization', 'skills', 'supervisor_info'])
            .current()
            .then((user: User) => {
              this.$store.dispatch('userAuthenticated', user);
              this.getUserFilters();
              this.redirect();
            })
            .catch((error: AxiosError) => {
              console.log('error', error);
              Token.delete(['access', 'refresh']);
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 503) {
          return ErrorHandler.alert(error.response.data.hint, error.response.data.message);
        }

        if (error.response.data && error.response.data.hint) {
          ErrorHandler.alert(error.response.data.hint, 'Inloggen mislukt');
        } else if (loginType && loginType !== 'refresh') {
          ErrorHandler.alert('Onjuiste inloggegevens ontvangen', 'Inloggen mislukt');
        }
      });
  }

  protected getUserFilters() {
    new UserFilter()
      .all()
      .then((userFilters: UserFilter[]) => {
        this.$store.dispatch('changeUserFilters', userFilters);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  public logout() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  public redirect() {
    if (this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect.toString());
    } else {
      this.$router.push('/reports');
    }
  }

  get emailRules() {
    return [(v: string) => !! v || this.$t('auth.required.email').toString()];
  }

  get passwordRules() {
    return [(v: string) => !! v || this.$t('auth.required.password').toString()];
  }
}

interface AuthenticationPayload {
  username?: string;
  password?: string;
  token?: string;
  refresh_token?: string;
  grant_type: string;
  client_id: number;
  client_secret: string;
  scope: string;
}
