var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-center": "",
        "justify-center": "",
        row: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "text-sm-center",
          attrs: { xs10: "", md8: "", lg6: "", xl4: "" }
        },
        [
          _c("header", [
            _vm.isAcceptance
              ? _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: require("@/assets/images/logos/AcceptatieOmgeving-Mijnbouwschade.png")
                  }
                })
              : _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("@/assets/images/logos/logo-img.png") }
                })
          ]),
          _c(
            "div",
            { staticClass: "elementPanel elementPanel--shadowed" },
            [
              _c(
                "a",
                {
                  staticClass: "selectionLink",
                  attrs: { href: "https://www.schadeexpertise-groningen.nl/" }
                },
                [_vm._v("Selecteer een andere omgeving")]
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("form.formFields.email")))]),
                  _c("v-text-field", {
                    attrs: {
                      name: "username",
                      outline: "",
                      "single-line": "",
                      rules: _vm.emailRules,
                      required: ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login($event)
                      }
                    },
                    model: {
                      value: _vm.username,
                      callback: function($$v) {
                        _vm.username = $$v
                      },
                      expression: "username"
                    }
                  }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("form.formFields.password")))
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      name: "password",
                      rules: _vm.passwordRules,
                      type: "password",
                      outline: "",
                      "single-line": "",
                      required: ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login($event)
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("form.formFields.token")))]),
                  _c("v-text-field", {
                    attrs: {
                      name: "token",
                      type: "token",
                      outline: "",
                      "single-line": "",
                      required: ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login($event)
                      }
                    },
                    model: {
                      value: _vm.token,
                      callback: function($$v) {
                        _vm.token = $$v
                      },
                      expression: "token"
                    }
                  }),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        wrap: "",
                        "justify-space-between": "",
                        "align-start": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "MiButton",
                            {
                              attrs: { round: "" },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.login($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("auth.login")))]
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { "text-xs-right": "" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }